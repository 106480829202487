// <script>
//*************************************************************************************************
//		Price Comparison Script v4 copyright Kaon Softwares ( http://www.kaonsoftwares.com )
//=================================================================================================
//		NOTICE: Do NOT remove the copyright and/or license information from this file.
//				doing so will automatically terminate your rights to use this program.
//*************************************************************************************************

function validateForm(f)
{
	for (var i=0; i<f.elements.length; i++)
	{
		// Continue if ALT tag is not defined
		if (f.elements[i].alt == "" || f.elements[i].alt == null || f.elements[i].alt == undefined)
			continue;
			
		// Find the value of ALT tag
		var elementTmp 	 = f.elements[i].alt;
		var elementSplit = elementTmp.indexOf('||');
		var elementAlt 	 = '';
		var elementTxt 	 = '';
		
		// Now split ALT tag in COMMAND & ALERT TEXT
		if ( elementSplit > 0 )
		{
			elementAlt = elementTmp.substr(0, elementSplit);
			elementTxt = elementTmp.substr(elementSplit+2);

			//alert("OriAlt: "+f.elements[i].alt+"\nAlt: "+elementAlt+"\nSplit: "+elementSplit+"\nTxt: "+elementTxt);
		}
		else
		{
			elementAlt = elementTmp;
		}
		
		
		if (elementAlt=="blank" && f.elements[i].value=="")
		{
			alert(elementTxt==''?"Please provide "+f.elements[i].name:elementTxt);
			f.elements[i].focus();
			return false;
		}
		if (elementAlt=="email")
		{
			if ( f.elements[i].value == "" )
			{
				alert(elementTxt==''?"Please provide email address":elementTxt);
				f.elements[i].focus();
				return false;
			}
			if ( f.elements[i].value.indexOf('@') < 1 )
			{
				alert(elementTxt==''?"Please provide valid email address":elementTxt);
				f.elements[i].focus();
				return false;				
			}
		}
		if (elementAlt=="numeric" && (f.elements[i].value=="" || isNaN(f.elements[i].value)))
		{
			alert(elementTxt==''?"Please provide "+f.elements[i].name:elementTxt);
			f.elements[i].focus();
			return false;
		}
		
		if (elementAlt.substr(0, 7) == "equalto")
		{
			if ( (f.elements[i].value != f[elementAlt.substr(8)].value) )
			{
				if (elementTxt=='')
					alert(f.elements[i].name+" should be same as "+f[elementAlt.substr(8)].name);
				else
					alert(elementTxt);
					
				f.elements[i].focus();
				return false;
			}
		}
	}

	return true;
}

function buildPOST(f)
{
    var qs = '';
   for (var i=0; i<f.elements.length; i++)
	{
		// Check if its a richeditor field
		if ( f.elements[i].type == 'hidden' )
		{
			try
			{
				oEditor = FCKeditorAPI.GetInstance(f.elements[i].name);
				f.elements[i].value = oEditor.GetXHTML(true);
			}
			catch (e) {}
		}

        if ( f.elements[i].name != '' )
		{
            var name = f.elements[i].name;
			
			if ( ( f.elements[i].type == 'checkbox' || f.elements[i].type == 'radio' ) && !f.elements[i].checked )
				continue;

			if ( f.elements[i].type == 'select-multiple' )
			{
				for (var j=0; j<f.elements[i].options.length; j++)
				{
					if ( f.elements[i].options[j].selected )
					{
			            qs += (qs == '') ? '' : '&';
			            qs += name + '=' + escape(f.elements[i].options[j].value);
					}
				}
	
				continue;
			}

            qs += (qs == '') ? '' : '&';
            qs += name + '=' + escape(f.elements[i].value);
        }
    }
    qs += "\n";

    return qs;
}

// </script>
