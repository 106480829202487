// <script>
//*************************************************************************************************
//		Price Comparison Script v6 copyright Kaon Softwares ( http://www.kaonsoftwares.com )
//=================================================================================================
//		NOTICE: Do NOT remove the copyright and/or license information from this file.
//				doing so will automatically terminate your rights to use this program.
//*************************************************************************************************

	var isAutoClose			= false;
	var autoCloseTimeout	= 1000;
	
	var ie5 = (document.all && document.getElementById);
	var ns6 = (!document.all && document.getElementById);
	var opac = 100;
	var opShow = 0;

	try { 
		document.execCommand('BackgroundImageCache', false, true); 
	} catch(e) {}

	function hidePop()
	{
		go2url = '';
		document.getElementById('divPopWin').style.display = 'none';
		updatePopMsgInline();
		opShow = 0;
		hidePopAct();
	}

	function hidePopAct()
	{
		if ( opShow == 1 )
			return;

		if ( opac < 100 )
		{
			opac += 20;
			if (ie5) document.getElementById('divFullBody').style.filter = 'alpha(opacity='+opac+')';
			if (ns6) document.getElementById('divFullBody').style.opacity = opac/100;
			setTimeout("hidePopAct()", 3);
		}
	}

	function showPop(html, bw, bh, icon, size, color, close)
	{
		bw		= (bw == undefined) ? 350 : bw;
		bh		= (bh == undefined) ? 150 : bh;
		icon	= (icon == undefined) ? '' : icon;
		size	= (size == undefined || size == '') ? 12 : size;
		color	= (color == undefined) ? '#777777' : color;
		close	= (close == undefined) ? false : close;

		var objPopWin			= document.getElementById('divPopWin');
		var objPopWinTopClose	= document.getElementById('divPopWinTopClose');
		var objPopWinIcon		= document.getElementById('divPopWinIcon');
		var objPopWinData		= document.getElementById('divPopWinData');
		var objPopWinButtons	= document.getElementById('divPopWinButtons');

		// Visible Body Dimensions
		if (self.innerHeight) // all except Explorer
		{
			iw = self.innerWidth;
			ih = self.innerHeight;
		}
		else if (document.documentElement && document.documentElement.clientHeight)	// Explorer 6 Strict Mode
		{
			iw = document.documentElement.clientWidth;
			ih = document.documentElement.clientHeight;
		}
		else if (document.body) // other Explorers
		{
			iw = document.body.clientWidth;
			ih = document.body.clientHeight;
		}

		// Scroll Position
		var sd = getScrollXY();
		var sl = sd[0];
		var st = sd[1];

		// Object Position
		var x = sl + (iw / 2) - (bw / 2);
		var y = st + (ih / 2) - (bh / 2);

		if ( document.all )
			objPopWin.style.min_height	= bh + 'px';
		else
			objPopWin.style.height		= bh + 'px';

		objPopWin.style.width			= bw + 'px';
		objPopWin.style.left			= x + 'px';
		objPopWin.style.top				= y + 'px';

		objPopWinData.innerHTML		= html;
// this makes the inner box larger than outer box and thus in ie7 inner box content breaks out from outer box
//		objPopWinData.style.width	= (bw-30) + 'px';
//		objPopWinData.style.height	= (bh-30) + 'px';
		objPopWinData.style.color	= color;
		objPopWinData.style.fontSize	= size+'px';

		if ( jsScriptRoot == undefined )
			jsScriptRoot = '';

		jQuery("#divPopWinIcon").html(icon == '' ? '' : '<img src="'+jsScriptRoot+'images/'+icon+'.gif" border=0 alt="">');

		objPopWinTopClose.style.display	= close ? 'block' : 'none';
		objPopWinButtons.style.display	= close ? 'block' : 'none';
		
		opShow = 1;
		showPopAct();
	}

	function showPopAct()
	{
		if ( opShow == 0 )
			return;

		if ( opac > 20 )
		{
			opac -= 20;
			if (ie5) document.getElementById('divFullBody').style.filter = 'alpha(opacity='+opac+')';
			if (ns6) document.getElementById('divFullBody').style.opacity = opac/100;
			setTimeout("showPopAct()", 3);
		}
		else
		{
			var obj = document.getElementById('divPopWin');
			obj.style.display = ie5 ? 'block' : 'table';
		}
	}

	function getScrollXY()
	{
		var scrOfX = 0, scrOfY = 0;
		if( typeof( window.pageYOffset ) == 'number' )
		{
			//Netscape compliant
			scrOfY = window.pageYOffset;
			scrOfX = window.pageXOffset;
		}
		else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) )
		{
			//DOM compliant
			scrOfY = document.body.scrollTop;
			scrOfX = document.body.scrollLeft;
		}
		else if( document.documentElement && ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) )
		{
			//IE6 standards compliant mode
			scrOfY = document.documentElement.scrollTop;
			scrOfX = document.documentElement.scrollLeft;
		}

		return [ scrOfX, scrOfY ];
	}

	function showPopError(msg)
	{
		showPop(msg, 400, 150, "error", "14", "#FF8888", true);
	}

	function showPopInfo(msg)
	{
		showPop(msg, 400, 150, "info", "14", "#888888", true);
		
		if ( isAutoClose )
			window.setTimeout("hidePop();", autoCloseTimeout);
	}

	function showPopWait(msg)
	{
		msg = (msg == undefined) ? "Saving... Please Wait..." : msg;
		showPop(msg, 400, 150, "loading", "24", "#888888", false);
//		window.setTimeout('popWaitTimeout()', 5000);
	}

	function popWaitTimeout()
	{
		if ( document.getElementById('divPopWin').style.display != 'none' )
		{
			if ( confirm("Timeout Occured\n - Click Ok to wait more\n - Click Cancel to close") )
				window.setTimeout('popWaitTimeout()', 5000);
			else
				hidePop();
		}
	}

	function updatePopMsgInline(msg)
	{
		msg = (msg == undefined) ? "" : msg;
		
		oMsgInline = document.getElementById('divPopWinMsgInline');
		oMsgInline.innerHTML = msg;
		
		if ( msg == '' )
			oMsgInline.style.display = 'none';
		else
			oMsgInline.style.display = 'block';
	}

// </script>